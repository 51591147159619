import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {StaticQuery, graphql, Link} from "gatsby";
import Img from "gatsby-image";

import {Title, Button, Section, Box, Text} from "../../components/Core";
import {device} from "../../utils";

const ImageHalfContainer = styled(Box)`
  position: static;
  right: -260px;
  z-index: -1;
  height: 100%;

  .img-half {
    height: auto;
    @media ${device.lg} {
      height: 100%;
    }
  }
  @media ${device.lg} {
    position: absolute !important;
    height: 100%;
    right: -500px;
  }
  @media ${device.xl} {
    position: absolute !important;
    height: 100%;
    right: -370px;
  }
`;

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({theme}) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  left: 15px;
  bottom: 10%;
  border-radius: 50%;
  overflow: hidden;
  max-width: 50%;

  @media ${device.sm} {
    bottom: 15%;
    left: 0;
  }
  @media ${device.md} {
    max-width: 50%;
  }
  @media ${device.lg} {
    top: 55%;
    left: -95px;
    bottom: auto;
    max-width: 100%;
  }
`;

const Content1 = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: {eq: "image/gal/WhatsApp Image 2020-09-21 at 9.44.13 PM.jpeg"}) {
            childImageSharp {
              fixed(width: 758, height: 1000) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => (
        <div className="overflow-hidden">
          <Container>
            <Row>
              <Col md={8} lg={6} data-aos="fade-right" data-aos-duration="750" data-aos-once="true" data-aos-delay="500">
                <Section py={[5, null, null, "190px"]}>
                  <div className="omga-02__content-text section-title order-lg-1">
                    <Title>
                      Interior & exterior painters
                      <br className="d-none d-sm-block" />
                      servicing Sydney NSW
                    </Title>
                    <Text>
                      We specialise in residential, commercial, industrial and strata painting services. We also specialise in the following services: spray
                      painting, floor painting, decorative finishes, anti-graffiti coatings/treatments, wallpapering, wood staining, texture coating and
                      commercial roof coating.
                    </Text>
                    <Link to="/contact-us">
                      <Button mt={4}>Contact Us</Button>
                    </Link>
                  </div>
                </Section>
              </Col>
              <Col md={8} lg={6} className="position-relative mb-5 mb-lg-0">
                <ImageHalfContainer>
                  <div className="h-100">
                    <Img fixed={data.file.childImageSharp.fixed} className="img-half img-fluid" />
                  </div>
                </ImageHalfContainer>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    />
  );
};

export default Content1;
