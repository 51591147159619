import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {StaticQuery, graphql} from "gatsby";
import Img from "gatsby-image";

import {Title, Section, Box, Text} from "../../components/Core";

const ContentImg = styled(Box)`
  box-shadow: ${({theme}) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content2 = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          rightImage: file(relativePath: {eq: "image/gal/WhatsApp Image 2020-09-21 at 9.44.14 PM.jpeg"}) {
            childImageSharp {
              fixed(width: 730, height: 515) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          leftImage: file(relativePath: {eq: "image/gal/WhatsApp Image 2020-09-21 at 9.11.31 PM.jpeg"}) {
            childImageSharp {
              fixed(width: 350, height: 515) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section>
          <Container>
            <Row className="justify-content-center">
              <Col xl="7" lg="10" md="9">
                <div className="text-center">
                  <Title>Remedial & maintenances building repairs</Title>
                  <Text>
                    We have a vast knowledge and experience in building rectification and repair systems to maximise the performance and durability of your
                    asset.
                  </Text>
                </div>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col xs="5" lg="4" sm="5" className="mb-4">
                <ContentImg>
                  <Img fixed={data.leftImage.childImageSharp.fixed} alt="" className="img-fluid" />
                </ContentImg>
              </Col>
              <Col xs="7" lg="8" sm="7" className="mb-4">
                <ContentImg>
                  <Img fixed={data.rightImage.childImageSharp.fixed} className="img-fluid" />
                </ContentImg>
              </Col>
            </Row>
          </Container>
        </Section>
      )}
    />
  );
};

export default Content2;
