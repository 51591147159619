import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "gatsby";
import {Title, Button, Section, Box, Text, InputGroup} from "../../components/Core";

import {device} from "../../utils";

import imgL2HeroOval2 from "../../assets/image/png/ce-tag-trans.png";
import imgL2HeroBg from "../../assets/image/jpeg/hero-home.jpg";

const SectionStyled = styled(Section)`
  background: url(${imgL2HeroBg}) no-repeat;
  background-size: cover;
  background-position: bottom left;

  @media ${device.lg} {
    background-position: center;
  }
`;

const ImageBottom = styled(Box)`
  position: absolute;
  right: -13%;
  top: 54%;

  @media ${device.lg} {
    right: -23%;
  }
  @media ${device.xl} {
    right: -28%;
  }
`;

const Hero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled>
        <div className="pt-5"></div>
        <Container>
          <Row>
            <Col lg="5" className="order-lg-2">
              <div className="position-relative w-100 h-100">
                <ImageBottom data-aos="zoom-out" data-aos-duration="750" data-aos-once="true" data-aos-delay="1000" className="d-none d-lg-block">
                  <img src={imgL2HeroOval2} alt="" className="img-fluid" />
                </ImageBottom>
              </div>
            </Col>
            <Col md="9" lg="7" className="order-lg-1" data-aos="fade-right" data-aos-duration="750" data-aos-delay="500" data-aos-once="true">
              <Box py={[null, null, null, 5]} pr={5} pt={[4, null]}>
                <Title variant="hero">Painting and building service provider in Sydney</Title>
                <Text mb={4}>
                  Colour exchnage has built an enviable reputation across the industry for consistently delivering high quality, award winning painting and
                  building services across Sydney and the wider NSW region.
                </Text>
                <form action="/">
                  <div className="position-relative">
                    <Link to="/contact-us">
                      <Button
                        css={`
                      margin-top: 1rem;
                      @media${device.sm}{
                        margin-top: 0;
                        width:100%;
                        top: 50%;
                        right: 5px;
                        transform: translateY(-50%);
                      }

                      `}>
                        Contact us today!
                      </Button>
                    </Link>
                  </div>
                </form>
              </Box>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Hero;
