import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {StaticQuery, graphql, Link} from "gatsby";
import Img from "gatsby-image";

import {Title, Button, Section, Box, Text, InputGroup} from "../../components/Core";
import {device} from "../../utils";

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({theme}) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: 21%;
  right: 0;
  z-index: 2;
  border-radius: 500px;
  max-width: 50%;
  overflow: hidden;

  @media ${device.md} {
    max-width: 100%;
    right: 15%;
  }
`;

const CTA = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          file(relativePath: {eq: "image/gal/WhatsApp Image 2020-09-21 at 9.44.14 PM (1).jpeg"}) {
            childImageSharp {
              fixed(width: 380, height: 566) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      `}
      render={(data) => (
        <Section bg="ash">
          <Container>
            <Row className="align-items-center">
              <Col lg={6} md={10} className="">
                <div className="position-relative mb-5">
                  <div className="img-1" data-aos="fade-down" data-aos-duration="750" data-aos-once="true">
                    <Img
                      fixed={data.file.childImageSharp.fixed}
                      alt=""
                      className="img-fluid"
                      css={`
                        border-radius: 10px;
                      `}
                    />
                  </div>
                </div>
              </Col>
              <Col lg={6} md={9} className="" data-aos="fade-right" data-aos-duration="750" data-aos-once="true">
                <div className="">
                  <Title color="light">Contact us</Title>
                  <Text color="light" opacity={0.7} mb={4}>
                    If you have any questions please feel free to contact us via our enquiry form.
                  </Text>
                  <div className="pr-sm-5">
                    <Link to="/contact-us">
                      <Button width="100%" mt={3}>
                        Contact Us
                      </Button>
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </Section>
      )}
    />
  );
};

export default CTA;
